import React, { useEffect, useState } from 'react'
import { Card, CharlyIcon, ScreenLayout } from '@charlycares/ui'
import { useTranslation } from 'react-i18next'
import { Box, Button, Center, Divider, FlatList, Heading, HStack, Image, Spinner, Pressable, Text } from 'native-base'
import {
  useGetFamilyReviewsQuery,
  useGetProfileReviewsQuery,
  useGetUserQuery,
  useGetUserRatingsQuery
} from '@charlycares/data-access'
import { contactPaginatedData, formatDate, IReview, links, useRouter, UserRoles } from '@charlycares/shared'
import { Linking } from 'react-native'

import RatingsOverview from './common/RatingsOverview'

const ProfileReviewsScreen = () => {
  const { t, i18n } = useTranslation()
  const { navigate } = useRouter()

  const [page, setPage] = useState(1)

  const { data: user } = useGetUserQuery()
  const { data: ratings, refetch: refetchRating, isFetching } = useGetUserRatingsQuery({})
  const {
    data: angelReviews,
    isLoading: loadingReviews,
    refetch: refetchReviews,
    isSuccess: angelReviewSuccess
  } = useGetProfileReviewsQuery({ page: page }, { skip: user?.role !== UserRoles.ANGEL })
  const {
    data: familyReviews,
    isLoading: loadingFamilyReviews,
    refetch: refetchFamilyReviews,
    isSuccess: familyReviewSuccess
  } = useGetFamilyReviewsQuery(undefined, { skip: user?.role !== UserRoles.FAMILY })

  const [reviews, setReviews] = useState<IReview[]>([])

  useEffect(() => {
    if (angelReviewSuccess && !isFetching && angelReviews) {
      setReviews(state => contactPaginatedData(state, angelReviews))
    }
  }, [angelReviews, isFetching, angelReviewSuccess])

  useEffect(() => {
    if (familyReviewSuccess && !isFetching && familyReviews) {
      setReviews(state => contactPaginatedData(state, familyReviews))
    }
  }, [familyReviews, isFetching, familyReviewSuccess])

  if (!ratings) {
    return <ScreenLayout title={t('reviews_screen')} isLoading />
  }

  return (
    <ScreenLayout disableScroll title={t('reviews_screen')}>
      <FlatList
        flex={1}
        onRefresh={() => {
          setPage(1)
          refetchRating()

          if (user?.role === UserRoles.ANGEL) {
            refetchReviews()
          }
          if (user?.role === UserRoles.FAMILY) {
            refetchFamilyReviews()
          }
        }}
        refreshing={isFetching || loadingReviews || loadingFamilyReviews}
        onEndReachedThreshold={0.5}
        onEndReached={() => {
          if (user?.role === UserRoles.ANGEL) {
            if (
              angelReviews?.meta.last_page &&
              page < angelReviews?.meta.last_page &&
              !isFetching &&
              angelReviews?.meta.current_page === page
            ) {
              setPage(page + 1)
            }
          } else {
            if (
              familyReviews?.meta.last_page &&
              page < familyReviews?.meta.last_page &&
              !isFetching &&
              familyReviews?.meta.current_page === page
            ) {
              setPage(page + 1)
            }
          }
        }}
        data={reviews}
        keyExtractor={item => item.rating_id || item.booking_id}
        ListFooterComponent={() =>
          (page !== 1 && page !== angelReviews?.meta.last_page) ||
          isFetching ||
          loadingReviews ||
          loadingFamilyReviews ? (
            <Spinner mt="10px" />
          ) : null
        }
        ListHeaderComponent={() => (
          <>
            <Card>
              {!!ratings && (
                <RatingsOverview
                  title={user?.role === UserRoles.ANGEL ? t('familiesAboutYou') : t('angelsAboutYou')}
                  averageRating={ratings.average_rating}
                  reviewsCount={ratings.ratings_count}
                  ratings={ratings.category_ratings}
                />
              )}

              <Divider my="20px" />

              <Box flexDir="row" w="100%" justifyContent="space-between" alignItems="center">
                <Text fontSize={15} flex={1}>
                  {t('improveRating')}
                </Text>
                <Text
                  onPress={() =>
                    Linking.openURL(
                      links.ratings[user?.role as keyof typeof links.ratings][
                        i18n.language.includes('nl') ? 'nl' : 'en'
                      ]
                    )
                  }
                  color="alert.info.400"
                  ml="10px"
                >
                  {t('readMore')}
                </Text>
              </Box>
            </Card>

            {user?.role === UserRoles.ANGEL && (
              <Card flexDir="row" alignItems="center">
                <CharlyIcon color="primary.400" mr="15px" name="icn-family" size={30} />
                <Box flex={1}>
                  <Heading fontSize={16} mt="10px">
                    {t('addNewReferences')}
                  </Heading>
                  <Text fontSize={15} color="gray.800">
                    {t('fromPreviousFamily')}
                  </Text>
                </Box>
                <Button onPress={() => navigate('ProfileAngelAddReference')} size="xs">
                  {t('invite')}
                </Button>
              </Card>
            )}

            <Box mb="10px" />
          </>
        )}
        renderItem={({ item, index }) => (
          <Pressable
            _pressed={{
              opacity: 0.7
            }}
            isDisabled={user?.role === UserRoles.ANGEL && !item.has_review}
            opacity={user?.role === UserRoles.ANGEL && !item.has_review ? 0.5 : 1}
            onPress={() =>
              navigate(
                user?.role === UserRoles.ANGEL ? 'AngelReviewDetails' : 'FamilyReviewDetails',
                '/profile-reviews-details',
                {
                  ratingId: item.rating_id,
                  bookingId: item.booking_id
                }
              )
            }
            borderBottomWidth="1px"
            borderTopWidth={index === 0 ? 1 : 0}
            borderColor="gray.200"
            bg="white"
            px="20px"
            py="25px"
            flexDir="row"
            alignItems="center"
          >
            {!!item.profile?.image && typeof item.profile?.image === 'string' && (
              <Image
                w={user?.role === UserRoles.ANGEL ? '60px' : '40px'}
                borderRadius={user?.role === UserRoles.ANGEL ? '4px' : '50px'}
                h="40px"
                source={{ uri: item.profile.image }}
              />
            )}
            <Box ml={item.profile.image ? '10px' : undefined} flex={1}>
              <Heading numberOfLines={1} fontSize={16}>{`${item.profile.first_name} ${
                item.profile?.last_name ?? ''
              }`}</Heading>
              {item.start_date && <Text>{formatDate(item.start_date, 'MMMM DD, yyyy')}</Text>}
            </Box>
            <Center minW={item.average_rating ? '120px' : 0}>
              {!!item.average_rating && (
                <HStack alignItems="center" space="2px">
                  <Text fontSize={'15px'} fontWeight={600}>
                    {item.average_rating}
                  </Text>
                  <CharlyIcon name="star" size={15} />
                </HStack>
              )}
              {!item.has_review && (
                <Button disabled variant="outline" size="xs">
                  {user?.role === UserRoles.FAMILY ? t('writeReview') : t('pending_review_angel')}
                </Button>
              )}
            </Center>
            {(user?.role === UserRoles.FAMILY || item.has_review) && (
              <CharlyIcon name="icn-caret-right" color="gray.900" size={32} />
            )}
          </Pressable>
        )}
      />
    </ScreenLayout>
  )
}

export default ProfileReviewsScreen

ProfileReviewsScreen.navigationOptions = () => ({
  headerShown: false
})
